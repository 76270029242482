import $ from 'jquery';
import Cookies from 'universal-cookie';
import {CONFIG, ENDERECO_API} from '../../config/constant';
import Swal from 'sweetalert2';
import procurando3 from '../../assets/images/procurando3.gif';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
//const {KeyObject} = await
//const ENDERECO_API = CONFIG.ENDERECO_API;
const JWT_SECRET = CONFIG.jwt.secret;
const JWT_EXPIRES_TIME = CONFIG.jwt.timeout;
const cookies = new Cookies();
const jose = require('jose');
const signIn = async (string) => {
  const encodedString = string;
  const secret = new TextEncoder().encode(JWT_SECRET);
  const IssuerAudience = string.split('|');
  const Issuer = 'urn:emid:' + base64_encode(IssuerAudience[0]);
  const Audience = 'urn:uuid:' + base64_encode(IssuerAudience[1]);
  //const alg = 'HS256';
  const alg = 'NONE';
  const serviceToken = await new jose.SignJWT({userId: encodedString}).setProtectedHeader({alg}).setIssuedAt().setIssuer(Issuer).setAudience(Audience).setExpirationTime(JWT_EXPIRES_TIME).sign(secret);
  return serviceToken;
};
const verifyToken = async (serviceToken, uid, empid) => {
  const Issuer = 'urn:emid:' + base64_encode(empid);
  const Audience = 'urn:uuid:' + base64_encode(uid);
  const secret = new TextEncoder().encode(JWT_SECRET);
  const {payload} = await jose.jwtVerify(serviceToken, secret, {
    issuer: Issuer,
    audience: Audience,
  });
  return payload.userId;
};
const RestAcessoPost = (config) => {
  const trataLoginSuccess = (data, lembrar, email) => {
    if (data !== -1) {
      const empReg = data[0];
      const uReg = data[3];
      const u64 = base64_encode(uReg);
      const serviceToken = 'token'; //await signIn(empReg + '|' + uReg);
      if (lembrar === true) {
        localStorage.setItem('usrespcloud__', JSON.stringify(email));
      } else {
        localStorage.removeItem('usrespcloud__');
      }
      // if (data[12] === '0') {
      //   data[12] = '1';
      // }
      let menu = data[10];
      const dashboard = data[15];
      const access = {
        possuiEmpresas: data[11][0]['possuiEmpresas'],
        primeiroAcesso: data[11][0]['primeiroAcesso'],
        possuiNotas: data[11][0]['possuiNotas'],
        possuiCert: data[11][0]['possuiCert'],
      };
      const storage = base64_encode(JSON.stringify(data));
      // localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem(`dashConfig${u64}`, dashboard);
      sessionStorage.setItem(`ddesp`, storage);
      localStorage.setItem(`ddespU${u64}`, base64_encode(JSON.stringify([base64_encode(empReg), base64_encode(uReg)])));
      localStorage.setItem(`xpaccss${u64}`, base64_encode(JSON.stringify(access)));
      const nomeArr = data[4].split(' ');
      let nome = '';
      if (nomeArr.length > 2) {
        nome = nomeArr[0];
      } else {
        nome = data[4];
      }
      return [
        200,
        {
          message: 'ok',
          serviceToken,
          possuiEmpresas: data[11][0]['possuiEmpresas'],
          primeiroAcesso: data[11][0]['primeiroAcesso'],
          possuiNotas: data[11][0]['possuiNotas'],
          user: {
            id: empReg,
            uId: uReg,
            imgPerfil: data[12],
            menu: menu,
          },
        },
      ];
      // resolve([
      //   200,
      //   {
      //     message: 'ok',
      //     serviceToken,
      //     possuiEmpresas: data[11][0]['possuiEmpresas'],
      //     primeiroAcesso: data[11][0]['primeiroAcesso'],
      //     possuiNotas: data[11][0]['possuiNotas'],
      //     user: {
      //       id: empReg,
      //       uId: uReg,
      //       imgPerfil: data[12],
      //       menu: menu,
      //     },
      //   },
      // ]);
      // })
      // .catch((err) => {
      //   return [500, {message: 'Server Error'}];
      // });
    } else {
      return [200, {message: 'Dados não localizados'}];
    }
  };

  const Arr_ = JSON.parse(config.data);
  const caminho = Arr_.dest.trim();
  //const configContext = useContext(ConfigContext);
  window.Buffer = window.Buffer || require('buffer').Buffer;
  if (caminho === 'login') {
    const MySwal = Swal;
    MySwal.fire({
      title: 'Aguarde um momento!',
      html: `Estamos verificando seus dados de acesso!`,
      //icon: 'info',
      imageUrl: procurando3,
      allowOutsideClick: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      // didOpen: () => {
      //   MySwal.showLoading();
      // },
    });
    return new Promise(function (resolve, reject) {
      try {
        const {email, password, lembrar, dataGoogle, boolViaGoogle} = JSON.parse(config.data);
        const dataPost = [base64_encode(email), base64_encode(password), base64_encode(dataGoogle), base64_encode(boolViaGoogle)];
        let timerInterval;
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            ll: base64_encode('login'),
            dd: dataPost,
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            const login_ = trataLoginSuccess(data, lembrar, email);
            if (login_[0] === 200) {
              Swal.fire({
                title: 'Pronto!',
                html: `Olá, ${data[4]}! <br/><br/>Só mais um instante. <br/>Estamos redirecionando para a sua conta.<b></b>`,
                icon: 'success',
                allowOutsideClick: true,
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 2500,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector('b');
                  timerInterval = setInterval(() => {
                    let value = Swal.getTimerLeft();
                    value = value / 1000;
                    //timer.textContent = `${value.toFixed(0)}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                resolve(login_);
              });
            } else {
              Swal.fire({
                button: 'OK!',
                title: 'OPS',
                html: 'Não localizamos nenhuma conta com os dados informados!',
                icon: 'info',
              }).then((result) => {
                reject([500, {message: 'Server Error'}]);
              });
            }
            // if (data !== -1) {
            //   const empReg = data[0];
            //   const uReg = data[3];
            //   const u64 = base64_encode(uReg);
            //   const serviceToken = 'token'; //await signIn(empReg + '|' + uReg);
            //   if (lembrar === true) {
            //     cookies.set('usrespcloud__', email, {path: '/'});
            //   } else {
            //     cookies.remove('usrespcloud__', {path: '/'});
            //   }
            //   // if (data[12] === '0') {
            //   //   data[12] = '1';
            //   // }
            //   let menu = data[10];
            //   const dashboard = data[15];
            //   const access = {
            //     possuiEmpresas: data[11][0]['possuiEmpresas'],
            //     primeiroAcesso: data[11][0]['primeiroAcesso'],
            //     possuiNotas: data[11][0]['possuiNotas'],
            //     possuiCert: data[11][0]['possuiCert'],
            //   };
            //   const storage = base64_encode(JSON.stringify(data));
            //   // localStorage.clear();
            //   sessionStorage.clear();
            //   localStorage.setItem(`dashConfig${u64}`, dashboard);
            //   sessionStorage.setItem(`ddesp`, storage);
            //   localStorage.setItem(`ddespU${u64}`, base64_encode(JSON.stringify([base64_encode(empReg), base64_encode(uReg)])));
            //   localStorage.setItem(`xpaccss${u64}`, base64_encode(JSON.stringify(access)));
            //   let timerInterval;
            //   const nomeArr = data[4].split(' ');
            //   let nome = '';
            //   if (nomeArr.length > 2) {
            //     nome = nomeArr[0];
            //   } else {
            //     nome = data[4];
            //   }
            //   MySwal.fire({
            //     title: 'Pronto!',
            //     html: `Olá, ${data[4]}! <br/><br/>Só mais um instante, <br/>Estamos redirecionando para a sua conta.<b></b>`,
            //     icon: 'success',
            //     allowOutsideClick: true,
            //     showConfirmButton: false,
            //     timerProgressBar: true,
            //     timer: 2500,
            //     didOpen: () => {
            //       Swal.showLoading();
            //       const timer = Swal.getPopup().querySelector('b');
            //       timerInterval = setInterval(() => {
            //         let value = Swal.getTimerLeft();
            //         value = value / 1000;
            //         //timer.textContent = `${value.toFixed(0)}`;
            //       }, 100);
            //     },
            //     willClose: () => {
            //       clearInterval(timerInterval);
            //     },
            //   })
            //     .then((result) => {
            //       resolve([
            //         200,
            //         {
            //           message: 'ok',
            //           serviceToken,
            //           possuiEmpresas: data[11][0]['possuiEmpresas'],
            //           primeiroAcesso: data[11][0]['primeiroAcesso'],
            //           possuiNotas: data[11][0]['possuiNotas'],
            //           user: {
            //             id: empReg,
            //             uId: uReg,
            //             imgPerfil: data[12],
            //             menu: menu,
            //           },
            //         },
            //       ]);
            //     })
            //     .catch((err) => {
            //       reject([500, {message: 'Server Error'}]);
            //     });
            // } else {
            //   MySwal.fire({
            //     button: 'OK!',
            //     title: 'OPS',
            //     html: 'Não localizamos nenhuma conta com os dados informados!',
            //     icon: 'info',
            //   });
            //   resolve([200, {message: 'Dados não localizados'}]);
            // }
          },
          error: function (xhr, status, error) {
            //
            try {
              xhr.responseText = base64_decode(xhr.responseText);
              //
              cookies.remove('usrespcloud__', {path: '/'});
            } catch (ex) {
              xhr.responseText = 'auth fail';
            }
            if (xhr.responseText == 'auth fail' || xhr.responseText == '-1') {
              Swal.fire('Ops!', 'Não localizamos nenhuma conta com os dados informados!', 'info', {
                button: 'OK!',
              });
              resolve([200, {message: 'Dados não localizados'}]);
            } else if (xhr.responseText === 'bloqueado') {
              Swal.fire({
                button: 'OK!',
                title: 'OPS',
                html: 'O acesso da empresa está bloqueado.<br/><br/>Para maiores informações entre em contato com o atendimento@espiaonfe.com.br.',
                icon: 'info',
              });
              resolve([200, {message: 'Bloqueado'}]);
            } else if (xhr.responseText === 'blkconta') {
              abrirModalValidacao();
              function abrirModalValidacao() {
                Swal.fire({
                  button: 'OK!',
                  confirmButtonText: 'Validar código',
                  cancelButtonText: 'Cancelar',
                  reverseButtons: true,
                  showCancelButton: true,
                  title: 'Localizamos seus dados!',
                  html: `No entanto, sua conta ainda não está ativada.
                          <p class="mt-3">Para concluir o processo de ativação, verifique seu e-mail. Enviamos um código que você precisa inserir no campo abaixo.</p>                       
                          <input type="text" id="code" class="swal2-input w-100 mx-0 text-center" placeholder="Código de ativação" />
                          <div class='mt-1'>
                            <small class='f-12'>O código não chegou? <a href="#" id="reenviarCodigoLink">Reenviar</a></small>
                          </div>`,
                  icon: 'info',
                  didOpen: () => {
                    const linkReenviarCodigo = document.getElementById('reenviarCodigoLink');
                    linkReenviarCodigo.addEventListener('click', reenviarCodigo);
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    const code = [base64_encode(document.getElementById('code').value)];
                    if (code !== '') {
                      $.ajax({
                        url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                          ll: base64_encode('validaCodeBlok'),
                          dd: base64_encode(JSON.stringify(code)),
                        },
                        success: async function (data, status, xhr) {
                          data = base64_decode(data);
                          if (data === 'ok') {
                            Swal.fire({
                              button: 'OK!',
                              title: 'Sucesso!',
                              html: 'Sua conta foi ativada com sucesso!<br/>Faça o login novamente para acessar o sistema!',
                              icon: 'success',
                            }).then((result) => {
                              //RestAcessoPost(config);
                              //window.location.href = '/auth/login';
                            });
                          } else {
                            Swal.fire({
                              button: 'OK!',
                              title: 'OPS',
                              html: 'Código de ativação inválido!',
                              icon: 'info',
                            });
                          }
                        },
                        error: function (xhr, status, error) {
                          Swal.fire({
                            button: 'OK!',
                            title: 'OPS',
                            html: 'Código de ativação inválido!',
                            icon: 'info',
                          });
                        },
                      });
                    } else {
                      Swal.fire({
                        button: 'OK!',
                        title: 'OPS',
                        html: 'Código de ativação inválido!',
                        icon: 'info',
                      });
                    }
                  }
                });
                resolve([200, {message: 'Bloqueado'}]);
              }
              const reenviarCodigo = async () => {
                let ultimoEnvio = localStorage.getItem('ultimoEnvio') || 0;
                ultimoEnvio = parseInt(ultimoEnvio);
                const tempoEspera = 60000; // Tempo de espera em milissegundos 1 minuto
                const agora = Date.now();
                if (agora - ultimoEnvio >= tempoEspera) {
                  Swal.fire({
                    title: 'Sucesso!',
                    html: 'Código de validação reenviado.',
                    icon: 'success',
                  });
                  await $.ajax({
                    url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
                    type: 'POST',
                    dataType: 'json',
                    data: {
                      ll: base64_encode('reenviarCodigo'),
                      dd: dataPost,
                    },
                  })
                    .done(function (data, status, xhr) {
                      //requisição for bem-sucedida:
                      data = base64_decode(data);
                      Swal.fire({
                        title: 'Sucesso!',
                        html: 'Código de validação reenviado.',
                        icon: 'success',
                      });
                    })
                    .fail(function (xhr, status, error) {
                      // Falhou:
                      xhr.responseText = base64_decode(xhr.responseText);
                      console.error('Erro na requisição AJAX:', error);
                    });
                  ultimoEnvio = agora;
                  // Salva o novo tempo do último envio no armazenamento local
                  localStorage.setItem('ultimoEnvio', ultimoEnvio);
                  abrirModalValidacao();
                } else {
                  const intervalo = setInterval(() => {
                    const tempoRestante = Math.ceil((tempoEspera - (Date.now() - ultimoEnvio)) / 1000);
                    if (tempoRestante <= 0) {
                      clearInterval(intervalo);
                    } else {
                      Swal.update({
                        html: `Espere ${tempoRestante} segundos antes de reenviar o código.`,
                      });
                    }
                  }, 1000);
                  Swal.fire({
                    title: 'OPS',
                    html: `Espere ${Math.ceil((tempoEspera - (agora - ultimoEnvio)) / 1000)} segundos antes de reenviar o código.`,
                    icon: 'info',
                    confirmButtonText: 'OK',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      clearInterval(intervalo);
                      abrirModalValidacao();
                    }
                  });
                }
              };
            } else if (xhr.responseText === 'blk' || xhr.responseText === 'blkusuarioconta') {
              Swal.fire({
                button: 'OK!',
                title: 'OPS',
                html: 'Seu acesso foi bloqueado pelo dono da conta entre em contato com o mesmo para mais informações!',
                icon: 'info',
              });
              resolve([200, {message: 'Bloqueado'}]);
            }
            if (xhr.responseText === 'expirou') {
              Swal.fire('Ops!', 'Seu período de testes chegou ao fim!', 'info', {
                button: 'OK!',
              });
              resolve([200, {message: 'expirou'}]);
            } else if (xhr.responseText.indexOf('varias') > -1) {
              const vrArr = xhr.responseText.split('|');
              localStorage.setItem('tmpVrs', base64_encode(vrArr[1]));
              window.location.href = '/auth/acesso-varias-empresas';
              resolve([200, {message: 'varias'}]);
            }
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'cadastrar') {
    const MySwal = Swal;
    MySwal.fire({
      title: 'Aguarde!',
      html: 'Estamos verificando suas credenciais.',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });
    return new Promise(function (resolve, reject) {
      const dados_ = JSON.parse(config.data);
      const dados = dados_.dados;
      const dataPost = [base64_encode(JSON.stringify(dados))];
      let timerInterval;
      $.ajax({
        url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
        type: 'POST',
        dataType: 'json',
        data: {
          ll: base64_encode('cadastrar'),
          dd: base64_encode(JSON.stringify(dataPost)),
        },
        success: function (data, status, xhr) {
          data = JSON.parse(base64_decode(data));

          if (data !== -1) {
            const login_ = trataLoginSuccess(data, false, dados.email);

            if (login_[0] === 200) {
              Swal.fire({
                title: 'Pronto!',
                html: `Olá, ${data[4]}! <br/><br/>Só mais um instante, <br/>Estamos redirecionando para a sua conta.<b></b>`,
                icon: 'success',
                allowOutsideClick: true,
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 2500,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector('b');
                  timerInterval = setInterval(() => {
                    let value = Swal.getTimerLeft();
                    value = value / 1000;
                    //timer.textContent = `${value.toFixed(0)}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                resolve(login_);
              });
            } else {
              Swal.fire({
                button: 'OK!',
                title: 'OPS',
                html: 'Não localizamos nenhuma conta com os dados informados!',
                icon: 'info',
              });
              reject([500, {message: 'Server Error'}]);
            }
          }
        },
        error: function (xhr, status, error) {
          xhr.responseText = base64_decode(xhr.responseText);
          const data = xhr.responseText;
          // if (data === 'cadastrado') {
          //   Swal.fire({
          //     title: 'Pronto!',
          //     html: 'Sua conta foi cadastrada com sucesso!<br/>Acesse o e-mail informado para liberar o acesso.',
          //     icon: 'success',
          //     button: 'OK',
          //     dangerMode: false,
          //   }).then((willDelete) => {
          //     if (willDelete) {
          //       window.location.href = '/auth/login';
          //     } else {
          //       window.location.href = '/auth/login';
          //     }
          //     resolve([200, data]);
          //   });
          // } else
          if (data === 'master') {
            resolve([200, data]);
            Swal.fire('Ops!', 'Você já possui uma conta com este e-mail!', 'info', {
              button: 'OK',
            });
          } else if (data === 'key') {
            Swal.fire('Ops!', 'Você já possui uma conta com este e-mail, entre em contato com o nosso suporte para mais informações!', 'info', {
              button: 'OK',
            });
          } else if (data === 'erro') {
            Swal.fire({
              title: 'Ops!',
              html: 'Tivemos um problema ao cadastrar sua conta!<br/>Recomendamos que entre em contato com nosso suporte.',
              icon: 'info',
              button: 'OK',
              dangerMode: false,
            });
          } else {
            Swal.fire({
              title: 'Ops!',
              html: 'Tivemos um problema ao cadastrar sua conta!<br/>Recomendamos que entre em contato com nosso suporte.',
              icon: 'info',
              button: 'OK',
              dangerMode: false,
            });
          }
          // Swal.fire({
          //   title: 'OPS!',
          //   html: 'Tivemos um problema ao cadastrar sua conta!<br/>Recomendamos que entre em contato com nosso suporte.',
          //   icon: 'info',
          //   button: 'OK',
          //   dangerMode: false,
          // });
          reject(400);
        },
      });
    });
  } else if (caminho === 'resetarValida') {
    // passo 1 para resetar a senha
    return new Promise(function (resolve, reject) {
      const {email} = JSON.parse(config.data);
      const dataPost = [base64_encode(email)];
      $.ajax({
        url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
        type: 'POST',
        dataType: 'json',
        data: {
          ll: base64_encode('resetarValida'),
          dd: base64_encode(JSON.stringify(dataPost)),
        },
        success: function (data, status, xhr) {
          data = base64_decode(data);
          if (data === 'ok') {
            resolve([200, true]);
          } else {
            resolve([200, false]);
          }
        },
        error: function (xhr, status, error) {
          reject([400, false]);
        },
      });
    });
  } else if (caminho === 'resetarValidaCode') {
    return new Promise(function (resolve, reject) {
      const {dataPost} = JSON.parse(config.data);
      const dataPost_ = [base64_encode(JSON.stringify(dataPost))];
      $.ajax({
        url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
        type: 'POST',
        dataType: 'json',
        data: {
          ll: base64_encode('resetarValidaCode'),
          dd: base64_encode(JSON.stringify(dataPost_)),
        },
        success: function (data, status, xhr) {
          data = base64_decode(data);
          if (data === 'ok') {
            resolve([200, true]);
          } else {
            resolve([200, false]);
          }
        },
        error: function (xhr, status, error) {
          reject([400, false]);
        },
      });
    });
  } else if (caminho === 'resetarSenha') {
    return new Promise(function (resolve, reject) {
      const {data} = JSON.parse(config.data);
      const dataPost = [base64_encode(JSON.stringify(data))];
      $.ajax({
        url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
        type: 'POST',
        dataType: 'json',
        data: {
          ll: base64_encode('redefinir'),
          dd: base64_encode(JSON.stringify(dataPost)),
        },
        success: function (data, status, xhr) {
          data = base64_decode(data);
          if (data === 'ok') {
            resolve([200, true]);
          } else {
            resolve([200, false]);
          }
        },
        error: function (xhr, status, error) {
          reject([400, false]);
        },
      });
    });
  } else if (caminho === 'cadPrimeiraEmpA1') {
    return new Promise(function (resolve, reject) {
      try {
        const {dados} = JSON.parse(config.data);
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const storage = JSON.parse(usersRt);
        const emp = storage[0];
        const us = storage[3];
        const dataPost = [base64_encode(emp), base64_encode(us), base64_encode(JSON.stringify(dados))];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            pp: base64_encode('emp'),
            ll: base64_encode('cadEmpA1'),
            dd: base64_encode(JSON.stringify(dataPost)),
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            resolve([200, {data}]);
          },
          error: function (xhr, status, error) {
            xhr.responseText = base64_decode(xhr.responseText);
            if (xhr.responseText === 'senha-err') {
              Swal.fire({
                button: 'OK!',
                title: 'OPS',
                html: 'A senha do certificado está incorreta!',
                icon: 'info',
              });
            } else if (xhr.responseText === 'vencido') {
              Swal.fire({
                button: 'OK!',
                title: 'OPS',
                html: 'O certificado selecionado está vencido!',
                icon: 'info',
              });
            } else {
              Swal.fire({
                button: 'OK!',
                title: 'OPS',
                html: 'Ocorreu um erro inesperado ao subir o certificado!<br/>Tente novamente, mais tarde!',
                icon: 'info',
              });
            }
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'primeiroAcesso') {
    return new Promise(function (resolve, reject) {
      try {
        const {dados} = JSON.parse(config.data);
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const storage = JSON.parse(usersRt);
        const emp = storage[0];
        //const dataPost = [base64_encode(emp), dados];
        const dataPost = [emp, dados];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            ll: base64_encode('enviaForm'),
            dd: base64_encode(JSON.stringify(dataPost)),
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            storage[11][0] = {primeiroAcesso: false, possuiEmpresas: false};
            const cript = base64_encode(JSON.stringify(storage));
            sessionStorage.setItem('ddesp', cript);
            //reject ([400, { message: 'Dados não localizados' }]);
            resolve([200, {}]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'updateUserByTk') {
    return new Promise(function (resolve, reject) {
      try {
        const {dataPost} = JSON.parse(config.data);
        const dataPost_ = [base64_encode(JSON.stringify(dataPost))];
        
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            ll: base64_encode('updateUserByTk'),
            dd: base64_encode(JSON.stringify(dataPost_)),
            data: dataPost,
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'Dados não localizados'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'acessoVarias') {
    const MySwal = Swal;
    MySwal.fire({
      title: 'Aguarde um momento!',
      html: `Estamos verificando seus dados de acesso!`,
      //icon: 'info',
      imageUrl: procurando3,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      // didOpen: () => {
      //   MySwal.showLoading();
      // },
    });
    return new Promise(function (resolve, reject) {
      try {
        const {dataPost} = JSON.parse(config.data);
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            act: 'acessoVarias',
            dataPost: dataPost,
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            if (data !== -1) {
              const empReg = data[0];
              const uReg = data[3];
              const u64 = base64_encode(uReg);
              const serviceToken = 'token'; //await signIn(empReg + '|' + uReg);
              if (data[12] === '0') {
                data[12] = '1';
              }
              let menu = data[10];
              const dashboard = data[15];
              const access = {
                possuiEmpresas: data[11][0]['possuiEmpresas'],
                primeiroAcesso: data[11][0]['primeiroAcesso'],
                possuiNotas: data[11][0]['possuiNotas'],
                possuiCert: data[11][0]['possuiCert'],
              };
              const storage = base64_encode(JSON.stringify(data));
              // localStorage.clear();
              sessionStorage.clear();
              localStorage.setItem(`dashConfig${u64}`, dashboard);
              sessionStorage.setItem(`ddesp`, storage);
              localStorage.setItem(`ddespU${u64}`, base64_encode(JSON.stringify([base64_encode(empReg), base64_encode(uReg)])));
              localStorage.setItem(`xpaccss${u64}`, base64_encode(JSON.stringify(access)));
              let timerInterval;
              const nomeArr = data[4].split(' ');
              let nome = '';
              if (nomeArr.length > 2) {
                nome = nomeArr[0];
              } else {
                nome = data[4];
              }
              MySwal.fire({
                title: 'Pronto!',
                html: `Olá, ${data[4]}! <br/><br/>Só mais um instante, <br/>Estamos redirecionando para a sua conta.<b></b>`,
                icon: 'success',
                allowOutsideClick: false,
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 2500,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector('b');
                  timerInterval = setInterval(() => {
                    let value = Swal.getTimerLeft();
                    value = value / 1000;
                    //timer.textContent = `${value.toFixed(0)}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                resolve([
                  200,
                  {
                    message: 'ok',
                    serviceToken,
                    possuiEmpresas: data[11][0]['possuiEmpresas'],
                    primeiroAcesso: data[11][0]['primeiroAcesso'],
                    possuiNotas: data[11][0]['possuiNotas'],
                    user: {
                      id: empReg,
                      uId: uReg,
                      imgPerfil: data[12],
                      menu: menu,
                    },
                  },
                ]);
              });
            } else {
              MySwal.fire({
                button: 'OK!',
                title: 'OPS',
                html: 'Não localizamos nenhuma conta com os dados informados!',
                icon: 'info',
              });
              resolve([200, {message: 'Dados não localizados'}]);
            }
          },
          error: function (xhr, status, error) {
            //
            xhr.responseText = base64_decode(xhr.responseText);
            //
            try {
              cookies.remove('usrespcloud__', {path: '/'});
            } catch (ex) {}
            if (xhr.responseText == 'auth fail' || xhr.responseText == '-1') {
              Swal.fire('Ops!', 'Não localizamos nenhuma conta com os dados informados!', 'info', {
                button: 'OK!',
              });
              resolve([200, {message: 'Dados não localizados'}]);
            } else if (xhr.responseText === 'bloqueado') {
              Swal.fire({
                button: 'OK!',
                title: 'OPS',
                html: 'O acesso da empresa está bloqueado.<br/><br/>Para maiores informações entre em contato com o atendimento@espiaonfe.com.br.',
                icon: 'info',
              });
              resolve([200, {message: 'Bloqueado'}]);
            } else if (xhr.responseText === 'blkconta') {
              abrirModalValidacao();
              function abrirModalValidacao() {
                Swal.fire({
                  button: 'OK!',
                  confirmButtonText: 'Validar código',
                  cancelButtonText: 'Cancelar',
                  reverseButtons: true,
                  showCancelButton: true,
                  title: 'Localizamos seus dados!',
                  html: `No entanto, sua conta ainda não está ativada.
                          <p class="mt-3">Para concluir o processo de ativação, verifique seu e-mail. Enviamos um código que você precisa inserir no campo abaixo.</p>                       
                          <input type="text" id="code" class="swal2-input w-100 mx-0 text-center" placeholder="Código de ativação" />
                          <div class='mt-1'>
                            <small class='f-12'>O código não chegou? <a href="#" id="reenviarCodigoLink">Reenviar</a></small>
                          </div>`,
                  icon: 'info',
                  didOpen: () => {
                    const linkReenviarCodigo = document.getElementById('reenviarCodigoLink');
                    linkReenviarCodigo.addEventListener('click', reenviarCodigo);
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    const code = [base64_encode(document.getElementById('code').value)];
                    if (code !== '') {
                      $.ajax({
                        url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                          ll: base64_encode('validaCodeBlok'),
                          dd: base64_encode(JSON.stringify(code)),
                        },
                        success: async function (data, status, xhr) {
                          data = base64_decode(data);
                          if (data === 'ok') {
                            Swal.fire({
                              button: 'OK!',
                              title: 'Sucesso!',
                              html: 'Sua conta foi ativada com sucesso!<br/>Faça o login novamente para acessar o sistema!',
                              icon: 'success',
                            }).then((result) => {
                              //RestAcessoPost(config);
                              //window.location.href = '/auth/login';
                            });
                          } else {
                            Swal.fire({
                              button: 'OK!',
                              title: 'OPS',
                              html: 'Código de ativação inválido!',
                              icon: 'info',
                            });
                          }
                        },
                        error: function (xhr, status, error) {
                          Swal.fire({
                            button: 'OK!',
                            title: 'OPS',
                            html: 'Código de ativação inválido!',
                            icon: 'info',
                          });
                        },
                      });
                    } else {
                      Swal.fire({
                        button: 'OK!',
                        title: 'OPS',
                        html: 'Código de ativação inválido!',
                        icon: 'info',
                      });
                    }
                  }
                });
                resolve([200, {message: 'Bloqueado'}]);
              }
              const reenviarCodigo = async () => {
                let ultimoEnvio = localStorage.getItem('ultimoEnvio') || 0;
                ultimoEnvio = parseInt(ultimoEnvio);
                const tempoEspera = 60000; // Tempo de espera em milissegundos 1 minuto
                const agora = Date.now();
                if (agora - ultimoEnvio >= tempoEspera) {
                  Swal.fire({
                    title: 'Sucesso!',
                    html: 'Código de validação reenviado.',
                    icon: 'success',
                  });
                  await $.ajax({
                    url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
                    type: 'POST',
                    dataType: 'json',
                    data: {
                      ll: base64_encode('reenviarCodigo'),
                      dd: dataPost,
                    },
                  })
                    .done(function (data, status, xhr) {
                      //requisição for bem-sucedida:
                      data = base64_decode(data);
                      Swal.fire({
                        title: 'Sucesso!',
                        html: 'Código de validação reenviado.',
                        icon: 'success',
                      });
                    })
                    .fail(function (xhr, status, error) {
                      // Falhou:
                      xhr.responseText = base64_decode(xhr.responseText);
                      console.error('Erro na requisição AJAX:', error);
                    });
                  ultimoEnvio = agora;
                  // Salva o novo tempo do último envio no armazenamento local
                  localStorage.setItem('ultimoEnvio', ultimoEnvio);
                  abrirModalValidacao();
                } else {
                  const intervalo = setInterval(() => {
                    const tempoRestante = Math.ceil((tempoEspera - (Date.now() - ultimoEnvio)) / 1000);
                    if (tempoRestante <= 0) {
                      clearInterval(intervalo);
                    } else {
                      Swal.update({
                        html: `Espere ${tempoRestante} segundos antes de reenviar o código.`,
                      });
                    }
                  }, 1000);
                  Swal.fire({
                    title: 'OPS',
                    html: `Espere ${Math.ceil((tempoEspera - (agora - ultimoEnvio)) / 1000)} segundos antes de reenviar o código.`,
                    icon: 'info',
                    confirmButtonText: 'OK',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      clearInterval(intervalo);
                      abrirModalValidacao();
                    }
                  });
                }
              };
            } else if (xhr.responseText === 'blk' || xhr.responseText === 'blkusuarioconta') {
              Swal.fire({
                button: 'OK!',
                title: 'OPS',
                html: 'O acesso da empresa está bloqueado.<br/><br/>Para maiores informações entre em contato com o atendimento@espiaonfe.com.br.',
                icon: 'info',
              });
              resolve([200, {message: 'Bloqueado'}]);
            }
            if (xhr.responseText === 'expirou') {
              Swal.fire('Ops!', 'Seu período de testes chegou ao fim!', 'info', {
                button: 'OK!',
              });
              resolve([200, {message: 'expirou'}]);
            } else if (xhr.responseText.indexOf('varias') > -1) {
              const vrArr = xhr.responseText.split('|');
              localStorage.setItem('tmpVrs', base64_encode(vrArr[1]));
              window.location.href = '/auth/acesso-varias-empresas';
              resolve([200, {message: 'varias'}]);
            }
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'validaCodeBlok') {
    return new Promise(function (resolve, reject) {
      const {dataPost} = JSON.parse(config.data);

      const sessionS = JSON.parse(base64_decode(sessionStorage.getItem('ddesp')));
      const usersRt = base64_decode(localStorage.getItem(`ddespU${base64_encode(sessionS[3])}`));
      const users = JSON.parse(usersRt);
      const emp = users[0];
      const us = users[1];
      const dataPost_ = [base64_encode(emp),base64_encode(us), base64_encode(JSON.stringify(dataPost))];
      $.ajax({
        url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
        type: 'POST',
        dataType: 'json',
        data: {
          ll: base64_encode('validaCodeBlok'),
          dd: base64_encode(JSON.stringify(dataPost_)),
        },
        success: function (data, status, xhr) {
          data = base64_decode(data);          
            resolve([200, data]);
        },
        error: function (xhr, status, error) {
          reject([400, false]);
        },
      });
    });
  }
};
const RestAcessoGet = async (config) => {
  const caminho = config.dest.trim();

  if (caminho === 'me') {
    const sessionS = JSON.parse(base64_decode(sessionStorage.getItem('ddesp')));
    const usersRt = base64_decode(localStorage.getItem(`ddespU${base64_encode(sessionS[3])}`));
    const users = JSON.parse(usersRt);
    const emp = users[0];
    const us = users[1];
    let user = false;
    //const {Authorization} = config.headers;
    //const serviceToken = Authorization.split(' ')[1];
    // const verify_ = await verifyToken(serviceToken, us, emp);
    //const encodedString = (emp + '|' + us);
    user = true;
    return new Promise(function (resolve, reject) {
      try {
        /*if (verify_ === encodedString) {
          user = true;
        }
        if (!user) {
          reject([401, {message: 'Invalid Token'}]);
        }*/
        //reject ([400, { message: 'Dados não localizados' }]);
        resolve([
          200,
          {
            user: {
              id: users[0],
              uId: users[1],
            },
          },
        ]);
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'getVarias') {
    const usersRt = base64_decode(localStorage.getItem('tmpVrs'));
    //const users =
    //const {Authorization} = config.headers;
    //const serviceToken = Authorization.split(' ')[1];
    // const verify_ = await verifyToken(serviceToken, us, emp);
    //const encodedString = (emp + '|' + us);
    return new Promise(function (resolve, reject) {
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            act: 'getVarias',
            campos: usersRt,
          },
          success: function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject(400);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'checkData') {
    return new Promise(function (resolve, reject) {
      const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
      const storage = JSON.parse(usersRt);
      const emp = storage[0];
      const uid = storage[3];
      const dataPost = [base64_encode(emp), base64_encode(uid)];
      try {
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/acesso_controller.class.php`),
          type: 'Get',
          dataType: 'json',
          data: {
            ll: base64_encode('checkData'),
            dd: base64_encode(JSON.stringify(dataPost)),
          },
          success: async function (data, status, xhr) {
            resolve([200, JSON.parse(base64_decode(data))]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'reenviarCodigo') {
    return new Promise(function (resolve, reject) {
      const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
      const users = JSON.parse(usersRt);
      const emp = users[0];
      const uid = users[3];
      const email = users[6];
      const dataPost = [email, emp, uid];
      $.ajax({
        url: `${ENDERECO_API}src/api/controller/acesso_controller.class.php`,
        type: 'POST',
        dataType: 'json',
        data: {
          ll: base64_encode('reenviarCodigo'),
          dd: base64_encode(JSON.stringify(dataPost)),
        },
        success: function (data, status, xhr) {
          data = base64_decode(data);
          if (data === 'enviou') {
            resolve([200, true]);
          } else {
            resolve([200, false]);
          }
        },
        error: function (xhr, status, error) {
          reject([400, false]);
        },
      });
    });
  }
};
export {RestAcessoPost, RestAcessoGet};
