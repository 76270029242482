import $ from 'jquery';
import {ENDERECO_API} from '../../config/constant';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
const RestHistoryCtePost = (config) => {
  
  const Arr_ = JSON.parse(config.data);
  const caminho = Arr_.dest.trim();

  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const storage = JSON.parse(usersRt);
  const emp = storage[0];
  const uid = storage[3];
  const token = storage[14];

  switch (caminho) {
    case 'deleteMarcador':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost), token)];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('deleteMarcadores'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, _status, _xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'downXML':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('dwnXml'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'downloadLote':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('downloadLote'),
              dd: base64_encode(JSON.stringify(dataPost_)),
              tipo: 1,
            },
            success: async function (data, _status, _xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'downloadLoteTodas':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('downloadLoteTodas'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, _status, _xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'eventos':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('situacao'),
              dd: base64_encode(JSON.stringify(dataPost_)),
              tipo: 1,
            },
            success: async function (data, _status, _xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'hideMarcador':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('hideMarcadores'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, _status, _xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'investigar':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('investigar'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, _status, _xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'manifestacao':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('manifestacao'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, _status, _xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'pesqCte':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          /* props: users,
                      pesq: dataPost*/
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('pesqCte'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'printDacte':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('printDacte'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'salvaConfigTabela':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost, null, 2))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('updateConfigTabela'),
              dd: base64_encode(JSON.stringify(dataPost_)),
              cf: dataPost,
            },
            success: async function (data, _status, _xhr) {
              
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'salvaNovoMarcador':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('salvaNovoMarcadorCte'),
              dd: base64_encode(JSON.stringify(dataPost_)),
              tipo: 'cte',
            },
            success: async function (data, _status, _xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'setMarcador':
      return new Promise(function (resolve, reject) {
        
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('setMarcador'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, _status, _xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'showMarcador':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('showMarcadores'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, _status, _xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'situacao':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('situacao'),
              dd: base64_encode(JSON.stringify(dataPost_)),
              tipo: 0,
            },
            success: async function (data, _status, _xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
    case 'updateAnotacao':
      return new Promise(function (resolve, reject) {
        const {dataPost} = JSON.parse(config.data);
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Post',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('updateAnotacao'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, _status, _xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          reject([500, {message: err + ' Server Error'}]);
        }
      });
  } 
 
};

const RestHistoryCteGet = (config) => {
  
  const caminho = config.dest.trim();
  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const storage = JSON.parse(usersRt);
  const emp = storage[0];
  const uid = storage[3];

  switch (caminho) {
    case 'getConfigTableCte':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost = [base64_encode(emp), base64_encode(uid)];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('getConfigTableCte'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);              
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          
          reject([500, {message: err}]);
        }
      });
    case 'getTableCte':
      return new Promise(function (resolve, reject) {
        try {
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              act: 'getTableCte',
              key: emp,
              ukey: uid,
            },
            success: async function (data, status, xhr) {
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          
          reject([500, {message: err}]);
        }
      });
    case 'getMarcadores':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost = [base64_encode(emp), base64_encode(uid)];

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('getMarcadores'),
              dd: base64_encode(JSON.stringify(dataPost)),
              //tipo: 'Cte',
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          
          reject([500, {message: err}]);
        }
      });
    case 'getVinculados':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(config.dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'Get',
            dataType: 'json',
            data: {
              pp: base64_encode('cte'),
              ll: base64_encode('getVinculadosCte'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'erro'}]);
            },
          });
        } catch (err) {
          
          reject([500, {message: err}]);
        }
      });
  }
};

export {RestHistoryCtePost, RestHistoryCteGet};
