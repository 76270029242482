import $ from 'jquery';
import {ENDERECO_API} from '../../config/constant';
import { decode as base64_decode } from 'base-64';
// const usersRt = sessionStorage.getItem(`ddesp`);
// const users = usersRt !== null ? JSON.parse(base64_decode(usersRt)) : null;



const RestPlanosGet = (config) => {
  
    
  const usersRt = sessionStorage.getItem(`ddesp`);
  const users = usersRt !== null ? JSON.parse(base64_decode(usersRt)) : null;

  const caminho = config.dest.trim();
  const emp = users[0];
  const user = users[3];
  

  if (caminho === 'getAplicativosMeuPlano') {
    return new Promise(function (resolve, reject) {
      try {        
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/meuPlano_controller_dev.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            act: 'getAplicativosMeuPlano',
            key: emp,
            ukey: user,
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getMeuPlanoAtual') {
    const idPlano = users[8];
    return new Promise(function (resolve, reject) {
      try {
        
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/meuPlano_controller_dev.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            act: 'getMeuPlanoAtual',
            key: emp,
            ukey: user,
            idPlano: idPlano,
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
            
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getLogsConsumoCte') {
    return new Promise(function (resolve, reject) {
      try {
        
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/meuPlano_controller_dev.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            act: 'getLogsConsumoCte',
            key: emp,
            ukey: user,
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  
  } else if (caminho == 'getDadosCobranca') {
    return new Promise(function (resolve, reject) {
      const cnpjCpf = users[2];
      
      try {
        
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/meuPlano_controller_dev.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            act: 'getDadosCobranca',
            key: emp,
            ukey: user,
            cnpjCpf: cnpjCpf,
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  

  }
};


const RestPlanosPost = (config) => {
  
  const usersRt = sessionStorage.getItem('ddesp');
  
  const users = usersRt !== null ? JSON.parse(base64_decode(usersRt)) : null;
  const emp = users[0];
  const uid = users[3];
  const Arr_ = JSON.parse(config.data);
  const caminho = Arr_.dest.trim();
  if (caminho === 'updateFaixaCte') {

    return new Promise(function (resolve, reject) {
      const {dataPost} = JSON.parse(config.data);
      

      try {
        
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/meuPlano_controller_dev.class.php`,
          type: 'Post',
          dataType: 'json',
          data: {
            act: 'updateFaixaCte',
            key: emp,
            ukey: uid,
            dataPost: dataPost,
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: error}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'updateDadosCobranca') {
    return new Promise(function (resolve, reject) {
      const {dataPost} = JSON.parse(config.data);
      
      
      try {
        
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/meuPlano_controller_dev.class.php`,
          type: 'Post',
          dataType: 'json',
          data: {
            act: 'updateDadosCobranca',
            key: emp,
            ukey: uid,
            dataPost: dataPost,
          },
          success: async function (data, status, xhr) {
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: error}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  
  }
};

export {RestPlanosPost, RestPlanosGet};