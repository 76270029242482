import React, {useContext, useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {LoginProvider} from './contexts/loginContext';
import routes, {renderRoutes} from './routes';
import {BASENAME} from './config/constant';
import './components/Utilitarios/ensure-basename';
import {ConfigContext} from './contexts/ConfigContext';
import Swal from 'sweetalert2';
import axios from './services/axios';
import {Toaster} from 'react-hot-toast';
import ModalEspionar from './views/modal/espionar/modalEspionar';
import {decode as base64_decode} from 'base-64';
//import {GoogleReCaptchaProvider, GoogleReCaptcha} from 'react-google-recaptcha-v3';

const App = () => {
  const configContext = useContext(ConfigContext);
  const {dispatch} = configContext;
  const {ws} = configContext.state;
  //const location = useLocation();
  useEffect(() => {
    window.onbeforeunload = (event) => {
      try {
        const {ws} = configContext.state;
        if (ws !== null) {
          try {
            ws.onclose = () => {};
          } catch (error) {}
          //const e = event || window.event;
          // Cancel the event
          //
          // e.preventDefault();
          // if (e) {
          //
          //   e.returnValue = ''; // Legacy method for cross browser support
          //   ws.onoclose = () => {
          //
          //   };
          // }
          // return ''; // Legacy method for cross browser support
        }
      } catch (error) {}
    };

    handleTimerUser();
  }, []);

  let soma = 0;
  const handleTimerUser = () => {
    setTimeout(() => {
      if (window.location.href.indexOf('auth') === -1) {
        let timerInterval;
        axios.get('/api/initData/', {dest: 'checkUserTokenAcesso'}).then((res) => {
          const response = res.data;
          if (response.message === 'logout') {
            Swal.fire({
              title: 'Sessão Duplicada!',
              html: 'Foi iniciada uma nova sessão com seu usuário.<br/> Esta sessão será encerrada!<br/><b></b>',
              icon: 'warning',
              showConfirmButton: false,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 5000,
              didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector('b');
                timerInterval = setInterval(() => {
                  let value = Swal.getTimerLeft();
                  value = value / 1000;
                  timer.textContent = `${value.toFixed(0)}`;
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              window.location.href = '/login';
              // localStorage.clear();
              sessionStorage.clear();
            });
          } else {
            soma++;
            handleTimerUser();
          }
        });
      }
    }, 300000); // 5 minutos
  };

  useEffect(() => {
    if (window.location.href.indexOf('auth') === -1) {
      try {
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const users = JSON.parse(usersRt);
        const expirou = users[29];
        // 
        // const history = ReloadHistory();
        // 

        //
        if (expirou === true) {
          // setOcultaMenus(true);
          if (window.location.href.indexOf('planos') === -1) {
            // history.push('/planos');

            //window.location.href = '/planos';

            return;
          }
        }
        //  if (WSCONNECT === false) return;
        //  if (ws === null) {
        //    const wsNew = new WebSocket('ws://192.168.0.192:8080');
        //    wsNew.onopen = () => {
        //      dispatch({type: WS, ws: wsNew});
        //      const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        //      const users = JSON.parse(usersRt);
        //      const emp = base64_encode(users[0]);
        //      const user = base64_encode(users[3]);
        //      const pass = base64_encode(users[14]);
        //      wsNew.send(JSON.stringify({type: 'connect', data: [emp, user, pass, users[4], users[12]]}));
        //    };
        //    wsNew.onerror = (err) => {
        //      //
        //    };
        //  }
      } catch (error) {}
    }
  }, [window.location.href]);

  return (
    <React.Fragment>
      <Router basename={BASENAME}>
        <LoginProvider>{renderRoutes(routes)}</LoginProvider>
        {configContext.state.espionarSefaz === true && <ModalEspionar />}
      </Router>
      <Toaster position='top-right' />
    </React.Fragment>
  );
};

export default App;
