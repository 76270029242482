import React, {useContext} from 'react';
import {Redirect} from 'react-router-dom';
import {decode as base64_decode} from 'base-64';
import useAuth from '../../hooks/useAuth';
import {ConfigContext} from '../../contexts/ConfigContext';

const GuestGuard = ({children}) => {
  const configContext = useContext(ConfigContext);
  const {isLoggedIn} = useAuth();
  if (isLoggedIn) {
    // const Pa = sessionStorage.getItem(`ddesp`);
    // const PaArr = Pa === null ? null : JSON.parse(base64_decode(Pa));

    const primeiroAcesso = configContext.state.primeiroAcesso;
    const possuiEmpresas = configContext.state.possuiEmpresas;
    const tipoTeste = configContext.state.tipoTeste;
    //const possuiNotas = PaArr === null ? false : PaArr[11][0]['possuiNotas'];
    const possuiNotas = false;

    // if (primeiroAcesso === true) {
    //   return <Redirect to='/inicio/boasVindas' />;
    // } else if (possuiEmpresas === false) {
    //   return <Redirect to='/inicio/tipoTeste' />;
    // } else {
    return <Redirect to='/dashboard' />;
    // }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;
