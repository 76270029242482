import React, { useContext, useState, useEffect } from 'react';
import { ListGroup, Dropdown, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ChatList from "./ChatList";
import {ConfigContext} from "../../../../contexts/ConfigContext";
import useAuth from '../../../../hooks/useAuth';
import { decode as base64_decode } from 'base-64';


import avatar1 from '../../../../assets/images/ic8/avatar.png';

let username = '';

const NavRight = () => {
    const configContext = useContext(ConfigContext);
    const { logout } = useAuth();
    const { rtlLayout } = configContext.state;

    const [listOpen, setListOpen] = useState(false);

    useEffect(() => {
        const usersRt = sessionStorage.getItem(`ddesp`) !== null ? JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`))) : null;
        
        if(usersRt !== null) {
            const username_ = usersRt[4].split(' ');
            username = username_[0];
        } 
    })

    const handleLogout = async () => {
        try {
            //handleClose();
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <React.Fragment>
            <ListGroup as='ul' bsPrefix=' ' className="navbar-nav ml-auto">
               
                <ListGroup.Item as='li' bsPrefix=' '>
                    <Dropdown alignRight={!rtlLayout} className="drp-user">
                        <Dropdown.Toggle as={Link} variant='link' to='#' id="dropdown-basic">
                            <img src={avatar1} className="img-radius wid-40" alt="User Profile"/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="profile-notification">
                            <div className="pro-head">
                                <img src={avatar1} className="img-radius" alt="User Profile"/>
                                <span>{username}</span>
                                <Link to='#' className="dud-logout" title="Logout" onClick={handleLogout}>
                                    <i className="feather icon-log-out"/>
                                </Link>
                            </div>
                            <ListGroup as='ul' bsPrefix=' ' variant='flush' className="pro-body">
                                <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item" onClick={handleLogout}><i className="feather icon-log-out"/> Sair</Link></ListGroup.Item>
                            </ListGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>
            </ListGroup>
            <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
        </React.Fragment>
    );
};

export default NavRight;
