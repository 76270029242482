import React, {useContext, useEffect, useState} from 'react';
import {ListGroup, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import NavGroup from './NavGroup';
import {ConfigContext} from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';

const NavContent = ({navigation}) => {
  const [scrollWidth, setScrollWidth] = useState(0);
  const [prevDisable, setPrevDisable] = useState(true);
  const [nextDisable, setNextDisable] = useState(false);

  const configContext = useContext(ConfigContext);
  const {dispatch} = configContext;

  const {layout, rtlLayout, hideButtonText} = configContext.state;

  const scrollPrevHandler = () => {
    const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;

    let scrollWidthPrev = scrollWidth - wrapperWidth;
    if (scrollWidthPrev < 0) {
      setScrollWidth(0);
      setPrevDisable(true);
      setNextDisable(false);
    } else {
      setScrollWidth(scrollWidthPrev);
      setPrevDisable(false);
    }
  };

  const scrollNextHandler = () => {
    const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;
    const contentWidth = document.getElementById('sidenav-horizontal').clientWidth;

    let scrollWidthNext = scrollWidth + (wrapperWidth - 80);
    if (scrollWidthNext > contentWidth - wrapperWidth) {
      scrollWidthNext = contentWidth - wrapperWidth + 80;

      setScrollWidth(scrollWidthNext);
      setPrevDisable(false);
      setNextDisable(true);
    } else {
      setScrollWidth(scrollWidthNext);
      setPrevDisable(false);
    }
  };

  const [existeEspionar, setExisteEspionar] = useState(false);

  let espionarSefaz = false;
  const navItems = navigation.map((item, index) => {
    if (item.id === '19') {
      if (item.children) {
        const findEspionar = item.children.find((item) => item.id === '32' || item.id === '37' || item.id === '38');
        const index = item.children.indexOf(findEspionar);
        if (index > -1) {
          //item.children.splice(index, 1);
          //setExisteEspionar(true);
          espionarSefaz = true;
        }
      }
      return false;
    }

    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            layout={layout}
            key={'nav-group-' + item.id}
            group={item}
          />
        );
      default:
        return false;
    }
  });

  let scrollStyle = {
    marginLeft: '-' + scrollWidth + 'px',
  };

  if (layout === 'horizontal' && rtlLayout) {
    scrollStyle = {
      marginRight: '-' + scrollWidth + 'px',
    };
  }

  useEffect(() => {
    setExisteEspionar(espionarSefaz);
    
  }, []);

  let mainContent = '';
  if (layout === 'horizontal') {
    let prevClass = ['sidenav-horizontal-prev'];
    if (prevDisable) {
      prevClass = [...prevClass, 'disabled'];
    }
    let nextClass = ['sidenav-horizontal-next'];
    if (nextDisable) {
      nextClass = [...nextClass, 'disabled'];
    }

    mainContent = (
      <div
        className='navbar-content sidenav-horizontal'
        id='layout-sidenav'
      >
        <Link
          to='#'
          className={prevClass.join(' ')}
          onClick={scrollPrevHandler}
        >
          <span />
        </Link>
        <div
          id='sidenav-wrapper'
          className='sidenav-horizontal-wrapper'
        >
          <ListGroup
            variant='flush'
            bsPrefix=' '
            as='ul'
            id='sidenav-horizontal'
            className='nav pcoded-inner-navbar sidenav-inner pb-3 '
            onMouseLeave={() => {
              dispatch({type: actionType.NAV_CONTENT_LEAVE});
            }}
            style={scrollStyle}
          >
            <ListGroup.Item
              as='li'
              bsPrefix=' '
              className='nav-item text-center mt-2 '
            >
              {existeEspionar === true && (
                <Button
                  variant='success'
                  className='btn-sm rounded'
                  onClick={() => {
                    dispatch({type: actionType.ESPIONAR_SEFAZ, espionarSefaz: true});
                  }}
                  onMouseLeave={() => dispatch({type: actionType.NAV_CONTENT_LEAVE})}
                >
                  <i className='fa fa-search'></i>
                  {!hideButtonText ? ' Espionar na SEFAZ' : ''}
                </Button>
              )}
            </ListGroup.Item>
            {navItems}
          </ListGroup>
        </div>
        <Link
          to='#'
          className={nextClass.join(' ')}
          onClick={scrollNextHandler}
        >
          <span />
        </Link>
      </div>
    );
  } else {
    mainContent = (
      <div className='navbar-content next-scroll'>
        <PerfectScrollbar>
          <ListGroup
            variant='flush'
            as='ul'
            bsPrefix=' '
            className='nav pcoded-inner-navbar pb-3'
            id='nav-ps-next'
          >
            {existeEspionar === true && (
              <ListGroup.Item
                as='li'
                bsPrefix=' '
                className='nav-item text-center mb-0'
              >
                <a
                  className='nav-link  btn btn-success btn-sm pb-3 text-white mt-3'
                  id='nav-15'
                  target=''
                  href='#'
                  onClick={() => dispatch({type: actionType.ESPIONAR_SEFAZ, espionarSefaz: true})}
                >
                  <span className='pcoded-micon'>
                    <i className='fa fa-search'></i>
                  </span>
                  <span className='pcoded-mtext'>Espionar na SEFAZ</span>
                </a>
              </ListGroup.Item>
            )}
            {navItems}
          </ListGroup>
        </PerfectScrollbar>
      </div>
    );
  }

  return <React.Fragment>{mainContent}</React.Fragment>;
};

export default NavContent;
