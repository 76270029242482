import $ from 'jquery';
import {ENDERECO_API} from '../../config/constant';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Swal from 'sweetalert2';

const RestDataInitPost = (config) => {
  const Arr_ = JSON.parse(config.data);
  const caminho = Arr_.dest.trim();
  if (caminho === 'dataIni') {
    return new Promise(function (resolve, reject) {
      try {
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const storage = JSON.parse(usersRt);
        const emp = storage[0];
        const uid = storage[3];
        const token = storage[14];
        const dataPost = [base64_encode(emp), base64_encode(uid)];
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`),
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('init'),
            ll: base64_encode('dataIni'),
            dd: base64_encode(JSON.stringify(dataPost)),
            tk: token,
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            if (data[0] === 'logout') {
              let timerInterval;
              Swal.fire({
                title: 'Sessão Duplicada!',
                html: 'Foi iniciada uma nova sessão com seu usuário.<br/> Esta sessão será encerrada!<br/><b></b>',
                icon: 'warning',
                showConfirmButton: false,
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 5000,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector('b');
                  timerInterval = setInterval(() => {
                    let value = Swal.getTimerLeft();
                    value = value / 1000;
                    timer.textContent = `${value.toFixed(0)}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                window.location.href = '/login';
                // localStorage.clear();
                sessionStorage.clear();

                //resolve([200, { data: [] }]);
              });
            } else {
              resolve([200, data]);
            }
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  } else if (caminho === 'deletarCPU') {
    return new Promise(function (resolve, reject) {
      try {
        const {dataPost} = JSON.parse(config.data);
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const storage = JSON.parse(usersRt);
        const emp = storage[0];
        const uid = storage[3];
        const token = storage[14];
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(dataPost)];
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/initDados_controller.class.php`),
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('init'),
            ll: base64_encode('deletarCPU'),
            dd: base64_encode(dataPost_),
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: 'Server Error'}]);
      }
    });
  }
};
const RestDataInitGet = (config) => {
  const caminho = config.dest.trim();
  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const storage = JSON.parse(usersRt);
  const emp = storage[0];
  const uid = storage[3];
  const token = storage[14];
  const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(config.dataPost))];
  if (caminho === 'logEmp') {
    return new Promise(function (resolve, reject) {
      try {
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`),
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('init'),
            ll: base64_encode('getLog'),
            dd: base64_encode(JSON.stringify(dataPost)),
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'logMonitor') {
    
    return new Promise(function (resolve, reject) {
      try {
        const dataPost = [base64_encode(emp), base64_encode(uid)];
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`),
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('init'),
            ll: base64_encode('logMonitor'),
            dd: base64_encode(JSON.stringify(dataPost)),
            //dataPost: dataPost,
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'checkUserTokenAcesso') {
    return new Promise(function (resolve, reject) {
      try {
        const dataPost = [base64_encode(emp), base64_encode(uid)];
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`),
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('init'),
            ll: base64_encode('checkUserTokenAcesso'),
            dd: base64_encode(JSON.stringify(dataPost)),
            tk: token,
            //dataPost: dataPost,
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            resolve([200, {message: data}]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'downPDF') {
    return new Promise(function (resolve, reject) {
      try {
        // const dataPost = [base64_encode(emp), base64_encode(uid), ];
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`),
          type: 'Get',
          dataType: 'application/pdf',
          data: {
            pp: base64_encode('init'),
            ll: base64_encode('downloadPdf'),
            dd: base64_encode(JSON.stringify(dataPost)),
            tk: token,
            //dataPost: dataPost,
          },
          success: async function (data, status, xhr) {
            data = base64_decode(data);
            resolve([200, {message: data}]);
          },
          error: function (xhr, status, error) {
            const response = base64_decode(xhr.responseText);
            const blob = new Blob([response], {type: 'application/pdf;charset=utf-8'});

            //   const url = window.URL.createObjectURL(blob);
            //   const link = document.createElement('a');
            //   link.href = url;
            //   link.download = 'danfe.pdf';
            //   document.body.appendChild(link);
            //   link.click();
            //   window.URL.revokeObjectURL(url);
            //   document.body.removeChild(link);

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'document.pdf');
            document.body.appendChild(link);
            link.click();
            resolve([200, {message: xhr.responseText}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getMunicipios') {
    return new Promise(function (resolve, reject) {
      try {
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`),
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('init'),
            ll: base64_encode('getMunicipios'),
            dd: base64_encode(JSON.stringify(dataPost)),
            tk: token,
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getSaldoCte') {
    return new Promise(function (resolve, reject) {
      try {
        $.ajax({
          url: encodeURI(`${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`),
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('init'),
            ll: base64_encode('getSaldoCte'),
            dd: base64_encode(JSON.stringify(dataPost)),
            tk: token,
          },
          success: async function (data, status, xhr) {
            data = JSON.parse(base64_decode(data));
            resolve([200, data]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  }
};

export {RestDataInitPost, RestDataInitGet};
